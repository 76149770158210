import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../App.css';


class AboutUs extends Component {

  componentDidMount () {
    const script = document.createElement("script");
  
    script.src = "https://platform.linkedin.com/badges/js/profile.js";
    script.async = true;
  
    document.body.appendChild(script);
  }

  render() {

    const { t, i18n } = this.props;
    
    return (
      <div id="menuItemAboutUs">
        <div id='aboutUs-title'>Words from Website Contributors</div>
        <p id='aboutUs-description'>We are the graduates of HKU (Class of 2020) in MSc Electronic Commerce and Internet Computing. 
        We are honoured to receive the Tang Chui Wai Hing Project Prize for 2019-20 academic year for the case study project, DigNews.
        </p>
        <p id='aboutUs-description'>During the beta launch stage, the website is free for access without prior registration. We will maintain the website with our best effort.
        Besides, we will keep up with the latest technologies available on Google Cloud Platform and introduce new features to DigNews if feasible.
        </p>
        <p id='aboutUs-description'>
        Last but not least, we hope to bring you a clean and intuitive solution to keep track with global news. Let's bookmark this site and spend a minute to read the global English news daily!
        Should you have any enquiry or suggestions to DigNews, please feel free to <a href="mailto:vincent@nevergiveup.hk">contact us</a>.
        </p>
        <table id='table-aboutUs'>
          <tr>
            <th>
              <a href='https://hk.linkedin.com/in/vincent-c-a404a317a' target="_blank">
                <img class='avatar' src='img_vincent.jpeg' />
              </a>
              <div>Vincent Chung (MHKCS, PMP)</div>
              <div>Project Lead</div>
            </th>
          </tr>
          <tr>
            <th>
              <a href='https://hk.linkedin.com/in/calvinwkl' target="_blank">
                <img class='avatar' src='img_calvin.png' />
              </a>
              <div>Calvin Wong</div>
              Development Lead
            </th>
          </tr>
          <tr>
            <th>
              <a href='https://hk.linkedin.com/in/marco-chiu-521a29146' target="_blank">
                <img class='avatar' src='img_marco.png' />
              </a>
              <div>Marco Chiu</div>
              Web Design Lead
              </th>
          </tr>
        </table>
      </div>
    );
  }
}

export default withTranslation('common')(AboutUs); // instead of 'export default App;'