import React, { Component } from 'react';
import { getPodcast } from '../utils/api';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '@material-ui/core';
import '../App.css';

class podcastWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playbackSpeed: 'Normal',
      loading: true
    };
  }

  async componentDidMount() {
    /**
    const pad = '00';
    const roundedDown = Math.floor(moment().minute() / 15) * 15;
    const roundedString =
      pad.substring(0, pad.length - roundedDown.toString().length) +
      roundedDown;
    
    let generatedTime = moment(
      moment().format('YYYY-MM-DD HH:') + roundedString,
      'YYYY-MM-DD HH:mm'
    );
    
    let generatedTimeString =
      moment().format('HH:') +
      roundedString +
      ' (' +
      generatedTime.fromNow() +
      ') ';
    */

    let podcast = document.createElement('audio');
    podcast.setAttribute('id', 'audioPlayer');
    podcast.setAttribute('controls', 'controls');
    podcast.setAttribute('preload', 'metadata');

    podcast.src = await getPodcast('en'); // await getPodcast('zh'); for hk news

    this.setState({loading: false});

    //document.getElementById('podcastInfo').append(generatedTimeString);
    document.getElementById('podcast').append(document.createElement('p', {}));
    document.getElementById('podcast').append(podcast);
    document.getElementById('podcast').append(document.createElement('p', {}));
  }

  render() {
    const { t } = this.props;
    const { loading } = this.state;

    //if (loading) { return null } else {
      return (
        <div id='podcastWidget'>
          <p>{t('main.news.podcastIntro', { framework: 'react-i18next' })}:</p>
          <div id='podcast' />
          {/**<span id='podcastInfo'>
            {t('main.news.generatedTime', { framework: 'react-i18next' })}{' '}
          </span>**/}
          <p></p>
          <hr />
          <span className='speedControl'>
            {t('main.currentPlaybackSpeed', { framework: 'react-i18next' })}:
          </span>
          <p></p>
          <ButtonGroup
            size='small'
            color='primary'
            aria-label='primary button group'
          >
            <Button
              className='audioSpeed'
              onClick={() => {
                document.getElementById('audioPlayer').playbackRate = 0.8;
                this.setState({ playbackSpeed: 'Slow' });
              }}
              variant= { this.state.playbackSpeed === "Slow" ? "contained" : "outlined" }
            >
              {t('main.audioSpeed-slow', { framework: 'react-i18next' })}
            </Button>
            <Button
              className='audioSpeed'
              onClick={() => {
                document.getElementById('audioPlayer').playbackRate = 1.0;
                this.setState({ playbackSpeed: 'Normal' });
              }}
              variant= { this.state.playbackSpeed === "Normal" ? "contained" : "outlined" }
            >
              {t('main.audioSpeed-normal', { framework: 'react-i18next' })}
            </Button>
            <Button
              className='audioSpeed'
              onClick={() => {
                document.getElementById('audioPlayer').playbackRate = 1.1;
                this.setState({ playbackSpeed: 'Fast' });
              }}
              variant= { this.state.playbackSpeed === "Fast" ? "contained" : "outlined" }
            >
              {t('main.audioSpeed-fast', { framework: 'react-i18next' })}
            </Button>
          </ButtonGroup>
          <hr />
        </div>
      );
    }
  }
//}
export default withTranslation('common')(podcastWidget);
