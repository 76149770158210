import React, { Component, Fragment } from 'react';
import { analytics, auth, googleProvider } from './utils/firebase';
import { getUser } from './utils/api';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import { BrowserRouter as Router } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PodcastWidget from './components/podcastWidget';
import NewsResult from './components/newsResult';
import GridNews from './components/gridNews';
import { Grid } from '@material-ui/core';
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import HomeIcon from '@material-ui/icons/Home';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import InfoIcon from '@material-ui/icons/Info';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import GroupIcon from '@material-ui/icons/Group';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, ButtonGroup } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CssBaseline from '@material-ui/core/CssBaseline';
import { orange, indigo } from '@material-ui/core/colors';
import './App.css';
import AboutUs from './menu/About';
import Podcast from './menu/Podcast';
import Project from './menu/Project';
import LiveVote from './menu/LiveVote';

const drawerWidth = 210;

const useStyles = (theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
});

class App extends Component {
  state = {
    user: null,
    trust: [],
    not_trust: [],
    isLoadingUserStatus: true,
    enableGridNews: false,
    darkState: false,
    openDrawer: false,
    component: 'home',
  };

  handleLoginClick = () => {
    this.setLoadingState(true);
    auth
      .signInWithRedirect(googleProvider)
      .then((result) => {
        this.setUserState(result.user, false);
        analytics.logEvent('login_success');
      })
      .catch(function (error) {
        console.log(error.message);
        this.setUserState(null, false);
        analytics.logEvent('login_fail');
      });
  };

  handleLogoutClick = () => {
    this.setLoadingState(true);
    auth.signOut().then((result) => {
      this.setUserState(null, false);
      analytics.logEvent('logout');
    });
  };

  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };

  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  handleThemeChange = () => {
    this.setDarkState();
  };

  enableGridNews = () => {
    this.setState({ enableGridNews: true });
  };

  disableGridNews = () => {
    this.setState({ enableGridNews: false });
  };

  setLoadingState = (loading) => {
    this.setState({ isLoadingUserStatus: loading });
  };

  setUserState = (user, loading) => {
    this.setState({ user, isLoadingUserStatus: loading });
  };

  setDarkState = () => {
    this.setState({ darkState: !this.state.darkState });
  };

  setComponent = (name) => {
    this.setState({ component: name, openDrawer: false });
  };

  async componentDidMount() {
    const { i18n } = this.props;
    i18n.changeLanguage('en');

    await auth.signInAnonymously();
    const user = auth.currentUser;
    const response = await getUser(user.uid, 'Anonymous User', 'anonymous');
    this.setState({
      user,
      displayName: response.user.name,
      trust: response.user.trust,
      not_trust: response.user.not_trust,
      isLoadingUserStatus: false,
      enableGridNews: false,
    });
  }

  render() {
    const { classes } = this.props;

    const { t, i18n } = this.props;
    const {
      user,
      displayName,
      isLoadingUserStatus,
      enableGridNews,
      darkState,
      openDrawer,
      component,
    } = this.state;

    const loginButton = user ? this.handleLogoutClick : this.handleLoginClick;

    const palletType = darkState ? 'dark' : 'light';
    const mainPrimaryColor = darkState ? orange[500] : indigo[800]; //orange[500] : indigo[900], deepOrange[900] : blueGrey[500]
    const darkTheme = createMuiTheme({
      palette: {
        type: palletType,
        primary: {
          main: mainPrimaryColor,
        },
      },
    });

    const displayNews =
      component === 'podcast' ? (
        <Podcast />
      ) : component === 'project' ? (
        <Project />
      ) : component === 'aboutus' ? (
        <AboutUs />
      ) : component === 'live_vote' ? (
        <LiveVote user={user} />
      ) : enableGridNews ? (
        <GridNews />
      ) : (
        <NewsResult user={user} />
      );

    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <Fragment>
            <AppBar
              position='fixed'
              className={clsx(classes.appBar, {
                [classes.appBarShift]: openDrawer,
              })}
            >
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={this.handleDrawerOpen}
                  edge='start'
                  className={clsx(
                    classes.menuButton,
                    openDrawer && classes.hide
                  )}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant='h6'>DigNews</Typography>
                <ButtonGroup
                  size='small'
                  color='inherit'
                  aria-label='contained primary button group'
                >
                  <Button
                    color='inherit'
                    size='small'
                    onClick={() => {
                      i18n.changeLanguage('en');
                      analytics.logEvent('switch_Language_en');
                    }}
                  >
                    EN
                  </Button>
                  <Button
                    color='inherit'
                    size='small'
                    onClick={() => {
                      i18n.changeLanguage('zh');
                      analytics.logEvent('switch_Language_zh');
                    }}
                  >
                    中
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </AppBar>
            <ClickAwayListener
              mouseEvent='onMouseDown'
              touchEvent='onTouchStart'
              onClickAway={this.handleDrawerClose}
            >
              <Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='left'
                open={this.state.openDrawer}
                classes={{ paper: classes.drawerPaper }}
              >
                <div className={classes.drawerHeader}>
                  <IconButton onClick={this.handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
                <Divider />
                <List>
                  {/* {displayName !== 'Anonymous User' && (
                    <ListItem button onClick={loginButton}>
                      <IconButton className={classes.menuButton}>
                        <ExitToAppIcon />
                      </IconButton>
                      {t(
                        isLoadingUserStatus
                          ? null
                          : user
                          ? 'main.buttonLogout'
                          : 'main.buttonLogin',
                        { framework: 'react-i18next' }
                      )}
                    </ListItem>
                      )*/}
                  {/*displayName !== 'Anonymous User' && (
                  <ListItem>
                    <IconButton className={classes.menuButton}>
                      <InfoIcon />
                    </IconButton>
                    {isLoadingUserStatus
                      ? null
                      : user
                      ? t('main.greeting', { framework: 'react-i18next' }) +
                        displayName
                      : t('main.statusNotLogin', {
                          framework: 'react-i18next',
                        })}
                  </ListItem>
                  )} */}
                  <ListItem button onClick={() => this.setComponent('live_vote')}>
                    <IconButton className={classes.menuButton}>
                      <HowToVoteIcon />
                    </IconButton>
                    {t('main.liveVote', { framework: 'react-i18next' })}
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem button>
                    <IconButton className={classes.menuButton}>
                      <Brightness4Icon />
                    </IconButton>
                    {t('main.darkMode', { framework: 'react-i18next' })}
                    <Switch
                      checked={darkState}
                      onChange={this.handleThemeChange}
                    />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.setComponent('home')}>
                    <IconButton className={classes.menuButton}>
                      <HomeIcon />
                    </IconButton>
                    {t('main.menu.home', { framework: 'react-i18next' })}
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => {
                      this.setComponent('podcast');
                    }}
                  >
                    <IconButton className={classes.menuButton}>
                      <AudiotrackIcon />
                    </IconButton>
                    {t('main.menu.podcast', { framework: 'react-i18next' })}
                  </ListItem>
                  <ListItem button onClick={() => this.setComponent('project')}>
                    <IconButton className={classes.menuButton}>
                      <FindInPageIcon />
                    </IconButton>
                    {t('main.menu.project', { framework: 'react-i18next' })}
                  </ListItem>
                  <ListItem button onClick={() => this.setComponent('aboutus')}>
                    <IconButton className={classes.menuButton}>
                      <GroupIcon />
                    </IconButton>
                    {t('main.menu.aboutus', { framework: 'react-i18next' })}
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem id='lastUpdate'>
                    {t('main.lastUpdateDate', { framework: 'react-i18next' })} 
                    13 Dec 2020
                  </ListItem>
                </List>
              </Drawer>
            </ClickAwayListener>

            <div className={classes.drawerHeader} />
            <Grid container spacing={3} style={{ margin: 0, width: '100%' }}>
              <Grid item xs={12} sm md lg={3}>
                <div id='statusArea'>
                  <ButtonGroup color='primary'>
                    <Button
                      onClick={this.enableGridNews}
                      variant={enableGridNews ? 'contained' : 'outlined'}
                    >
                      {t('main.gridnewsMode', { framework: 'react-i18next' })}
                    </Button>
                    <Button
                      onClick={this.disableGridNews}
                      variant={!enableGridNews ? 'contained' : 'outlined'}
                    >
                      {t('main.standardMode', { framework: 'react-i18next' })}
                    </Button>
                  </ButtonGroup>
                </div>
                <PodcastWidget />
              </Grid>
              <Grid item xs sm={12} md={8} lg={9}>
                {displayNews}
              </Grid>
            </Grid>
          </Fragment>
          <VerticalAlignTopIcon
            className='BackToTop'
            onClick={scroll.scrollToTop}
          />
          <hr />
          <footer>
            <p><a href='https://newsapi.org' rel='noreferrer noopener' target='_blank' id='attribution'>Powered by News API</a></p>
            <p>{t('main.copyright', { framework: 'react-i18next' })}</p>
          </footer>
        </Router>
      </ThemeProvider>
    );
  }
}

export default withTranslation('common')(withStyles(useStyles)(App)); // instead of 'export default App;'
