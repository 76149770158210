import React, { Component } from 'react';
//import { translate } from 'react-i18next';
import '../App.css';


class Project extends Component {

  render() {

    //const { t, i18n } = this.props;

    return (
      <div class="container">
        <a href="/slides_dignews-382dfa/index.html" target="_blank">Public Presentation Slide</a>
        <iframe class="responsive-iframe" src="/slides_dignews-382dfa/index.html" title="Public Presentation Slide"></iframe>
      </div>
    );
  }
}

export default Project; // instead of 'export default App;'
