import axios from 'axios';

// Configure the parameters used in Google Cloud Text-to-Speech API
const gcpAPIKey = 'AIzaSyB8BOnBgw0O4hl5vLkjOkQeUa524WlpAXU';
const gcpBaseUrl = 'https://texttospeech.googleapis.com/';

// Configure the parameters used in backend
const backendUrl = process.env.NODE_ENV === 'production' ? 'https://asia-east2-dignews.cloudfunctions.net/': 'http://localhost:5001/dignews/asia-east2/';


export const feed = async (lang = 'en') => {
  const response = await axios.get(backendUrl + 'feed', {
    params: {
      lang
    }
  });
  return response.data;
};

export const getPodcast = async (lang = 'en') => {
  const response = await axios.get(backendUrl + 'podcast', {
    params: {
      lang
    }
  });
  return response.data;
};

export const speakText = async (script) => {
  const response = await axios.post(gcpBaseUrl + 'v1beta1/text:synthesize', 
    {
      'audioConfig': {
        'audioEncoding': 'MP3',
        'pitch': -4.0,
        'speakingRate': 1
      },
      'input': {
        'ssml': script
      },
      'voice': {
        'languageCode': 'en-US',
        'name': 'en-US-Wavenet-D'
      }
    }, 
    {
      headers: {
        'X-Goog-Api-Key': gcpAPIKey,
        'Content-Type': 'application/json'
      }
    }
  );
  return response.data;
};

export const getUser = async (userId, name, loginType) => {
  const response = await axios.post(backendUrl + 'user', 
    {
      userId,
      name,
      loginType
    }
  );
  return response.data;
}

export const vote = async (userId, mediaId, trust) => {
  const response = await axios.post(backendUrl + 'vote', 
    {
      userId,
      mediaId,
      trust: trust ? 'true' : 'false'
    }
  );
  return response.data;
}

export const sources = async () => {
  const response = await axios.get(backendUrl + 'sources');
  return response.data;
}
