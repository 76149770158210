import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import * as serviceWorker from './serviceWorker';
import common_en from './translations/en/common.json';
import common_zh from './translations/zh/common.json';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GA from './utils/googleAnalytics';
import App from './App';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    zh: {
      common: common_zh,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Router>
      {GA.init() && <GA.RouteTracker />}
      <div>
        <Switch>
          <Route exact path='/' component={App} />
        </Switch>
      </div>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
