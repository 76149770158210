import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import 'firebase/analytics';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBn1FtKOc1UOHxqPpRV9fnP4du6oE8l1Mg",
  authDomain: "dignews.firebaseapp.com",
  databaseURL: "https://dignews.firebaseio.com",
  projectId: "dignews",
  storageBucket: "dignews",
  messagingSenderId: "639120836930",
  appId: "1:639120836930:web:d24efcb7a44ea8504c60db",
  measurementId: "G-HD06Y6Y985"
};
firebase.initializeApp(firebaseConfig);
firebase.auth().languageCode = 'en';

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');

const auth = firebase.auth();
const analytics = firebase.analytics();
const storageRef = firebase.storage().ref();
const storage = firebase.storage();

export { analytics, auth, googleProvider, storage, storageRef };