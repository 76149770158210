import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { vote, sources } from '../utils/api';
import { analytics } from '../utils/firebase';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Doughnut } from 'react-chartjs-2';
import '../App.css';

const useStyles = (theme) => ({
    menuButton: {
        marginRight: theme.spacing(1),
    }
});

class LiveVote extends Component {

    constructor(props) {
        super(props);
        this.state = {
          mediaSources: undefined,
          labels: [
            'BBC',
            'CNN',
            'CNA',
            'SCMP'
          ],
            bbc_trust: 0,
            bbc_untrust: 0,
            cnn_trust: 0,
            cnn_untrust: 0,
            cna_trust: 0,
            cna_untrust: 0,
            scmp_trust: 0,
            scmp_untrust: 0
        };
      }

    async componentDidMount() {
        this.updateMediaSources();

        setInterval(() => {
            
            this.setState({ bbc_trust: this.state.mediaSources['bbc news'].trust_count});
            this.setState({ cnn_trust: this.state.mediaSources['cnn'].trust_count});
            this.setState({ cna_trust: this.state.mediaSources['cna'].trust_count});
            this.setState({ scmp_trust: this.state.mediaSources['post magazine'].trust_count});
            
            this.setState({ bbc_untrust: this.state.mediaSources['bbc news'].not_trust_count});
            this.setState({ cnn_untrust: this.state.mediaSources['cnn'].not_trust_count});
            this.setState({ cna_untrust: this.state.mediaSources['cna'].not_trust_count});
            this.setState({ scmp_untrust: this.state.mediaSources['post magazine'].not_trust_count});

            this.setState({
                datasets: [{
                    data: [
                        this.state.bbc_trust, 
                        this.state.cnn_trust, 
                        this.state.cna_trust, 
                        this.state.scmp_trust
                    ],
                    backgroundColor: [
                        '#FF6384',
                        '#36A2EB',
                        '#FFCE56',
                        '#00bfa5'
                    ]
                }]
            });
        }, 1000);
    }

    updateMediaSources = async () => {
        const response = await sources();
        this.setState({ mediaSources: response.result });
    };

    handleTrustClick = async (media, trust) => {
        const { user } = this.props;
        await vote(user.uid, media.toLowerCase(), trust);
        analytics.logEvent('vote_mediaSources_en');
        this.updateMediaSources();
    };

  render() {
    const { classes } = this.props;
    const { t, user } = this.props;
    const { mediaSources } = this.state;

    return (
      <div id="menuItemLiveVote">
        <Typography variant='subtitle1'>
            Public Opinion Poll
        </Typography>
        <List id='voteMedia'>
            <ListItem>
                <ListItemText primary='BBC' />
                <ListItemSecondaryAction>
                    <IconButton className={classes.menuButton}>
                        <ThumbUpIcon 
                            variant='outlined'
                            style={{ color: '#64dd17' }}
                            onClick={() => this.handleTrustClick('bbc news', true) }
                            disabled={!user}
                        />
                    </IconButton>
                    <IconButton className={classes.menuButton}>
                        <ThumbDownIcon 
                            variant='outlined'
                            style={{ color: '#d50000' }}
                            onClick={() => this.handleTrustClick('bbc news', false) }
                            disabled={!user}
                        />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <ListItemText primary='CNA' />
                <ListItemSecondaryAction>
                    <IconButton className={classes.menuButton}>
                        <ThumbUpIcon 
                            variant='outlined'
                            style={{ color: '#64dd17' }}
                            onClick={() => this.handleTrustClick('cna', true) }
                            disabled={!user}
                        />
                    </IconButton>
                    <IconButton className={classes.menuButton}>
                        <ThumbDownIcon 
                            variant='outlined'
                            style={{ color: '#d50000' }}
                            onClick={() => this.handleTrustClick('cna', false) }
                            disabled={!user}
                        />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <ListItemText primary='CNN' />
                <ListItemSecondaryAction>
                    <IconButton className={classes.menuButton}>
                        <ThumbUpIcon 
                            variant='outlined'
                            style={{ color: '#64dd17' }}
                            onClick={() => this.handleTrustClick('CNN', true) }
                            disabled={!user}
                        />
                    </IconButton>
                    <IconButton className={classes.menuButton}>
                        <ThumbDownIcon 
                            variant='outlined'
                            style={{ color: '#d50000' }}
                            onClick={() => this.handleTrustClick('CNN', false) }
                            disabled={!user}
                        />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
                <ListItemText primary='SCMP' />
                <ListItemSecondaryAction>
                    <IconButton className={classes.menuButton}>
                        <ThumbUpIcon 
                            variant='outlined'
                            style={{ color: '#64dd17' }}
                            onClick={() => this.handleTrustClick('post magazine', true) }
                            disabled={!user}
                        />
                    </IconButton>
                    <IconButton className={classes.menuButton}>
                        <ThumbDownIcon 
                            variant='outlined'
                            style={{ color: '#d50000' }}
                            onClick={() => this.handleTrustClick('post magazine', false) }
                            disabled={!user}
                        />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
        <Typography variant='subtitle1'>
            Trustworthiness of News Media
        </Typography>
        <p>
            {mediaSources && mediaSources['bbc news'] &&
            (<span id='voteResult'>{`${mediaSources['bbc news'].trust_count} users (${Math.round(mediaSources['bbc news'].trust_ratio * 1000) / 10}%) trust BBC.`}</span>)}
        </p>
        <p>
            {mediaSources && mediaSources['cna'] &&
            (<span id='voteResult'>{`${mediaSources['cna'].trust_count} users (${Math.round(mediaSources['cna'].trust_ratio * 1000) / 10}%) trust CNA.`}</span>)}            
        </p>
        <p>
            {mediaSources && mediaSources['cnn'] &&
            (<span id='voteResult'>{`${mediaSources['cnn'].trust_count} users (${Math.round(mediaSources['cnn'].trust_ratio * 1000) / 10}%) trust CNN.`}</span>)}       
        </p>
        <p>
            {mediaSources && mediaSources['post magazine'] &&
            (<span id='voteResult'>{`${mediaSources['post magazine'].trust_count} users (${Math.round(mediaSources['post magazine'].trust_ratio * 1000) / 10}%) trust SCMP.`}</span>)}
        </p>

        <div id="chart">
        <Doughnut data={this.state} />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withStyles(useStyles)(LiveVote)); // instead of 'export default App;'
