import React, { Component } from 'react';
import { render } from 'react-dom';
import { feed } from '../utils/api';
import moment from 'moment-timezone';
import Gallery from 'react-grid-gallery';
import CircularProgress from '@material-ui/core/CircularProgress';
//import data from '../utils/enNewsResponse';

class GridNews extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      lastImageURL: null,
      advertisementImage: 
      [
        'https://s3.amazonaws.com/media-p.slid.es/uploads/1354435/images/7533909/BigD_4x.png',
        'https://s3.amazonaws.com/media-p.slid.es/uploads/1354435/images/7533908/DigNews_4x.png'
      ],
      loading: true,
    };
  }

  async componentDidMount() {
    // get feed
    const news = await feed('en'); // await feed('zh'); for hk news
    this.setState({ data: news.articles });
    
    var newsImage = this.state.data.map((news) => {
      //Randomly assign advertisment image if found duplicated images
      const n = Math.floor(Math.random() * this.state.advertisementImage.length);

      if (news.urlToImage!==this.state.lastImageURL) {
        this.setState({ lastImageURL: news.urlToImage });
        return {
          src: `${news.urlToImage}`,
          thumbnailWidth: 345,
          thumbnailHeight: 200,
          thumbnail: `${news.urlToImage ? (news.urlToImage.substr(0,5)==='https' ? news.urlToImage : news.urlToImage.replace('http','https')) : news.urlToImage}`,
          caption:
            ' [ ' +
            `${news.source.name}` +
            ' • ' +
            `${moment(news.publishedAt)
              .tz('Asia/Hong_Kong')
              .format('hh:mm A')}` +
            ' ] ' +
            `${news.title}`,
          alt: ''
        };
      } else {
        return {
          src: `${this.state.advertisementImage[n]}`,
          thumbnailWidth: 512,
          thumbnailHeight: 512,
          thumbnail: `${this.state.advertisementImage[n]}`,
          caption: 'This is DigNews Advertisement. Hope you had spent time to take a look.',
          alt: '`${news.url}`'
        };
      }
    });

    this.setState({ loading: false });

    render(
      <Gallery images={newsImage} enableImageSelection={false} backdropClosesModal={true} />,
      document.getElementById('gridNews')
    );
  }

  render() {
    const { loading } = this.state;
    if (loading) { 
      return <div id='loadingNews'><CircularProgress /></div> 
    } else {
      return <div id='gridNews'></div>;
    }
  }
}

export default GridNews;
