import React, { Component } from 'react';
import { storageRef } from '../utils/firebase';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import 'date-fns';
import '../../node_modules/react-datepicker/dist/react-datepicker.css';
import '../App.css';

class Podcast extends Component {
  state = {
    date: null
  };

  setDate = (date) => {
    this.setState({ date: date });
  }

  render() {

    const { t, i18n } = this.props;
    /**
    storageRef.child('podcast/en/'+moment().format('YYYYMMDD')+'-08-00.mp3').getDownloadURL().then(function(url) {
      document.getElementById('morningPodcast').src=url;
    });

    storageRef.child('podcast/en/'+moment().format('YYYYMMDD')+'-16-00.mp3').getDownloadURL().then(function(url) {
      document.getElementById('afternoonPodcast').src=url;
    });

    storageRef.child('podcast/en/'+moment().format('YYYYMMDD')+'-20-00.mp3').getDownloadURL().then(function(url) {
      document.getElementById('nightPodcast').src=url;
    });
**/
    if (this.state.date!== null) {
      storageRef.child('podcast/en/'+moment(this.state.date).format("YYYYMMDD")+'.mp3').getDownloadURL().then(function(url) {
        document.getElementById('customPodcast').src=url;
      });
    }
/**
    const morningNewsTitle = (new Date().getHours() < 8) ? t('podcastPage.morningNews.toBeRelease', { framework: 'react-i18next' }) : t('podcastPage.morningNews.at', { framework: 'react-i18next' });
    const afternoonNewsTitle = (new Date().getHours() < 13) ? t('podcastPage.afternoonNews.toBeRelease', { framework: 'react-i18next' }) : t('podcastPage.afternoonNews.at', { framework: 'react-i18next' });
    const nightNewsTitle = (new Date().getHours() < 20) ? t('podcastPage.nightNews.toBeRelease', { framework: 'react-i18next' }) : t('podcastPage.nightNews.at', { framework: 'react-i18next' });
    //const customNewsTitle = 'Custom News at ' + moment(this.state.date).format('LLLL') + ':';
 */ 
   
    return (
      <div id="menuItemPodcast">
        {/**
          <h2>{t('podcastPage.title', { framework: 'react-i18next' })}</h2>
          <p>{morningNewsTitle}</p>
          <audio id='morningPodcast' controls></audio>
          <p>{afternoonNewsTitle}</p>
          <audio id='afternoonPodcast' controls></audio>
          <p>{nightNewsTitle}</p>
          <audio id='nightPodcast' controls></audio>
        **/}
        <h2>{t('podcastPage.newsPodcastRepository', { framework: 'react-i18next' })}</h2>
          <p>{t('podcastPage.selectDate', { framework: 'react-i18next' })}</p>
          {t('podcastPage.customNewsOn', { framework: 'react-i18next' })}  <DatePicker
              selected={this.state.date}
              onChange={this.setDate}
              /**
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              dateFormat="MMMM d, yyyy h:mm aa"
              */
              dateFormat="MMMM d, yyyy"
              maxDate={new Date()}
              minDate={new Date('December 5, 2020')}
            />
        <p></p>
        <audio id='customPodcast' controls></audio>
      </div>
    );
  }
}

export default withTranslation('common')(Podcast); // instead of 'export default App;'
