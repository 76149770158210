import React, { Component } from 'react';
import { feed, vote, sources } from '../utils/api';
import moment from 'moment-timezone';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import { analytics } from '../utils/firebase';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import './reactTableCustom.css';
import '../../node_modules/react-table/react-table.css';
import '../App.css';

class NewsResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filtered: [],
      mediaItem: [],
      mediaSources: undefined,
      loading: true
    };
  }

  async componentDidMount() {
    // get feed
    const news = await feed('en'); // await feed('zh'); for hk news
    this.setState({ data: news.articles });
    this.updateMediaSources();
    
    this.setState({loading: false});
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter['id'] === accessor) {
          if (value === '' || !value.length) filtered.splice(i, 1);
          else filter['value'] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };

  updateMediaSources = async () => {
    const response = await sources();
    this.setState({ mediaSources: response.result });
  };

  handleTrustClick = async (media, trust) => {
    const { user } = this.props;
    await vote(user.uid, media.toLowerCase(), trust);
    analytics.logEvent('vote_mediaSources_en');
    this.updateMediaSources();
  };

  render() {
    const { t, user } = this.props;
    const { data, mediaSources, loading } = this.state;

    if (loading) { 
      return <div id='loadingNews'><CircularProgress /></div> 
    } else {
      return (
        <div id='newsResult'>
            {t('main.news.selectNewsSource', {framework: 'react-i18next'})}
            <Select
              className='selectMedia'
              onChange={(event) => {
                if(event.target.value==='All'){
                  this.setState({filtered: []});
                } else {
                  this.setState({ mediaItem: event.target.value });
                  this.onFilteredChangeCustom(event.target.value,'source.name');
                }
              }}
            >
              <MenuItem value={'All'}>{t('main.news.source.all', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'BBC NEWS'}>{t('main.news.source.bbc', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'CNA'}>{t('main.news.source.cna', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'CNBC'}>{t('main.news.source.cnbc', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'CNN'}>{t('main.news.source.cnn', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'RTHK.HK'}>{t('main.news.source.rthk', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'POST MAGAZINE'}>{t('main.news.source.scmp', {framework: 'react-i18next'})}</MenuItem>
              <MenuItem value={'THE WALL STREET JOURNAL'}>{t('main.news.source.wsj', {framework: 'react-i18next'})}</MenuItem>
            </Select>
            <Button variant="contained" color="primary" onClick={() => {this.setState({filtered: []})}}>{t('main.clearFilter', {framework: 'react-i18next'})}</Button>

          <ReactTable
            data={data}
            //filterable
            filtered={this.state.filtered}
            onFilteredChange={(filtered, column, value) => {
              this.onFilteredChangeCustom(value, column.id || column.accessor);
            }}
            defaultFilterMethod={(filter, row, column) => {
              const id = filter.pivotId || filter.id;
              if (typeof filter.value === 'object') {
                return row[id] !== undefined
                  ? filter.value.indexOf(row[id]) > -1
                  : true;
              } else {
                return row[id] !== undefined
                  ? String(row[id]).indexOf(filter.value) > -1
                  : true;
              }
            }}
            columns={[
              {
                columns: [
                  {
                    Header: t('main.news.columnName', {
                      framework: 'react-i18next',
                    }),
                    id: 'source.name',
                    accessor: (news) => {
                      return news.source.name.toUpperCase();
                    },
                    Cell: (news) => (
                      <div className='text-wrap'>
                        <div
                          style={{
                            width: '100%',
                            height: '15px',
                            backgroundColor: '#ffffff',
                            borderRadius: '10px',
                          }}
                        >
                          <div
                            style={{
                              height: '100%',
                              backgroundColor:
                                mediaSources &&
                                mediaSources[
                                  news.original.source.name.toLowerCase()
                                ]
                                  ? mediaSources[
                                      news.original.source.name.toLowerCase()
                                    ].trust_ratio > 0.66
                                    ? '#32CD32'
                                    : mediaSources[
                                        news.original.source.name.toLowerCase()
                                      ].trust_ratio > 0.33
                                    ? '#FFD700'
                                    : '#A52A2A'
                                  : '#ff2e00',
                              borderRadius: '10px',
                              transition: 'all .2s ease-out',
                              opacity: 0.8,
                            }}
                          ></div>
                        </div>
                        <p></p>
                        <h2>{news.original.title}</h2>
                        <p></p>
                        {t('main.news.reportedBy', {
                          framework: 'react-i18next',
                        })}{' '}
                        {news.original.source.name.toUpperCase()}
                        <p></p>
                          <Button
                            variant='outlined'
                            color='secondary'
                            className='greenButton'
                            onClick={() =>
                              this.handleTrustClick(
                                news.original.source.name,
                                true
                              )
                            }
                            disabled={!user}
                          >
                            {t('main.news.buttonTrust', {
                              framework: 'react-i18next',
                            })}
                          </Button>&nbsp;&nbsp;
                          <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() =>
                              this.handleTrustClick(
                                news.original.source.name,
                                false
                              )
                            }
                            disabled={!user}
                          >
                            {t('main.news.buttonNotTrust', {
                              framework: 'react-i18next',
                            })}
                          </Button>
                        <p></p>
                          {mediaSources && mediaSources[news.original.source.name.toLowerCase()] &&
                          (<span id='voteResult'>{`${Math.round(mediaSources[news.original.source.name.toLowerCase()].trust_ratio * 1000) / 10}% of users (${mediaSources[news.original.source.name.toLowerCase()].trust_count} out of 
                          ${mediaSources[news.original.source.name.toLowerCase()].trust_count + mediaSources[news.original.source.name.toLowerCase()].not_trust_count}) trust ${news.original.source.name.toUpperCase()}. `}</span>)}
                        <p>
                          <a
                            rel='noreferrer noopener'
                            href={news.original.url}
                            target='_blank'
                          >
                            <img
                              className='newsImage'
                              src={news.original.urlToImage ? (news.original.urlToImage.substr(0,5)==='https' ? news.original.urlToImage : news.original.urlToImage.replace('http','https')) : news.original.urlToImage}
                              alt={news.title}
                            />
                          </a>
                        </p>
                        <p>
                          Published at{' '}
                          {moment(news.original.publishedAt)
                            .tz('Asia/Hong_Kong')
                            .fromNow()}
                        </p>
                        <p>{moment(news.original.publishedAt).format('llll')}</p>
                      </div>
                    ),
                  },
                ],
              },
            ]}
            defaultPageSize={10}
            showPagination={false}
          />
        </div>
      );
    }
  }
}
export default withTranslation('common')(NewsResult);
